<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col xm="6" sm="6" lg="5" xl="5" class="px-0 py-1 bg-secondary">
        <div
          class="d-flex flex-column justify-content-center align-items-center bg-primary w-100 h-100"
        >
          <b-img :src="logoBig" alt="Login Side Image" />
          <p class="text-white mt-5 px-5 text-center">
            Gestão de carteira, descomplicada e eficiente.
          </p>
        </div>
      </b-col>

      <b-col xm="6" sm="6" lg="7" xl="7" class="d-flex align-items-center auth-bg px-2 py-5 p-lg-5">
        <b-col sm="12" md="12" lg="12" xl="10" class="px-xl-10 px-lg-8 px-md-7 mx-auto">
          <b-card-title class="mb-1 font-weight-bold d-flex flex-column" title-tag="h2">
            <span class="text-dark">Crie sua conta</span>
          </b-card-title>
          <b-card-text class="mb-2"> Informe abaixo seus dados cadastrais. </b-card-text>

          <validation-observer ref="RegisterForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2">
              <div v-if="step === 1">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Nome" label-for="register-name">
                      <b-form-input v-model="name" name="register-name" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Email" label-for="register-email">
                      <b-form-input v-model="email" type="email" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Telefone" label-for="register-phone">
                      <validation-provider
                        #default="{ errors }"
                        name="Telefone"
                        vid="phone"
                        rules="required"
                      >
                        <b-form-input
                          id="phone"
                          v-model="phone"
                          :state="errors.length > 0 ? false : null"
                          name="phone"
                          type="tel"
                          v-mask="'(##) ####-####'"
                          placeholder="(00) 0000-0000"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group class="mt-1">
                      <b-form-checkbox v-model="terms" name="checkbox-terms" id="checkbox-terms">
                        <div>
                          Eu concordo com os
                          <a
                            href="https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/termos_uso.pdf"
                            target="_blank"
                            class="text-warning"
                          >
                            Termos de uso
                          </a>
                          e
                          <a
                            href="https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/politica_privacidade.pdf"
                            target="_blank"
                            class="text-warning"
                          >
                            Política de privacidade
                          </a>
                          da CreditCorp.
                        </div>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button
                      variant="warning"
                      @click="validateUserData"
                      class="continue-button"
                      :disabled="!terms || invalid"
                    >
                      <span class="text-dark font-weight-bolder">
                        Continuar <feather-icon icon="ChevronRightIcon" class="ml-50" />
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <div v-if="step === 2">
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="register-password">Senha</label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="Senha"
                        vid="password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="register-password"
                            v-model="password"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="register-password"
                            placeholder="Password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility('passwordFieldType')"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="register-password">Confirmação de senha</label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="Confirmação de senha"
                        vid="confirm_password"
                        rules="required|confirmed:password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="register-password-confirmation"
                            v-model="passwordConfirmation"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            :type="passwordConfirmationFieldType"
                            name="register-password-confirmation"
                            placeholder="Password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordConfirmToggleIcon"
                              @click="togglePasswordVisibility('passwordConfirmationFieldType')"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <span> Sua senha precisa conter: </span>
                    <b-form-group class="mt-1">
                      <b-form-checkbox :checked="minimunCaracters" name="checkbox-1" disabled>
                        No mínimo 8 caracteres
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox :checked="capitalLetters" name="checkbox-1" disabled>
                        Letras maiúsculas
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox :checked="smallLetters" name="checkbox-1" disabled>
                        Letras minúsculas
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox :checked="numbers" name="checkbox-1" disabled>
                        Números
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox :checked="symbols" name="checkbox-1" disabled>
                        Simbolos
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="d-flex justify-content-between">
                    <b-button variant="outline-warning" @click="step = 1">
                      <feather-icon icon="ChevronLeftIcon" class="mr-1" />
                      Voltar
                    </b-button>
                    <b-button
                      variant="warning"
                      @click="register"
                      :disabled="!strongPassword || !passwordsMatch"
                    >
                      <span class="text-dark font-weight-bolder"> Criar conta </span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email, cnpj } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mask } from 'vue-the-mask'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    mask,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      terms: false,
      step: 1,
      logoBig: require('@/assets/images/logo/pamcash-logo.svg'),
      password: '',
      passwordConfirmation: '',
      name: '',
      phone: '',
      email: '',
    }
  },
  async mounted() {
    if (!this.$route.query.token) return

    try {
      const data = await this.$store.dispatch('admin/validateInvite', this.$route.query.token)
      this.name = data.nome
      this.email = data.email
    } catch (error) {
      this.$swal({
        title: 'Erro',
        text: error.response.error,
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    minimunCaracters() {
      return this.password.length >= 8
    },
    smallLetters() {
      return /[a-z]/.test(this.password)
    },
    capitalLetters() {
      return /[A-Z]/.test(this.password)
    },
    numbers() {
      return /[0-9]/.test(this.password)
    },
    symbols() {
      return /[!@#\$%\^\&*\)\(+=._-]/.test(this.password)
    },
    strongPassword() {
      return (
        this.minimunCaracters &&
        this.smallLetters &&
        this.capitalLetters &&
        this.numbers &&
        this.symbols
      )
    },
    termsOfUse() {
      return process.env.VUE_APP_TERMS_OF_USE
    },
    privacyPolicy() {
      return process.env.VUE_APP_PRIVACY_POLICY
    },
    passwordsMatch() {
      return this.password === this.passwordConfirmation
    },
  },
  methods: {
    async validateUserData() {
      const success = await this.$refs.RegisterForm.validate()
      if (success) this.step = 2
    },
    async register() {
      try {
        const payload = {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          links_politicas_privacidade: ['https://linktermo1.com'],
          links_termos_uso: ['https://linktermo2.com'],
          token: this.$route.query.token,
          telefone: this.phone,
        }

        this.$swal.showLoading()

        await this.$store.dispatch('admin/acceptInvite', payload)

        this.$swal({
          title: 'Sucesso!',
          text: 'Usuário cadastrado com sucesso!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        this.$router.push('auth-login')
      } catch (error) {
        this.$refs.RegisterForm.setErrors(error.response)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.register-bg-cc {
  background: linear-gradient(180deg, #002b5e 0%, #0070af 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-bg-text {
  padding-left: 3rem;
  padding-right: 3rem;
}

.continue-button {
  margin-top: 1.5rem;
}

.img-max-width {
  max-width: 250px;
}

@media (min-width: 1679px) {
  .img-max-width {
    max-width: 450px !important;
  }

  .side-bg-text {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.px-xl-17 {
  padding-left: 17rem !important;
  padding-right: 17rem !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--warning) !important;
  background-color: var(--warning) !important;
}
</style>
